import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Campaign } from "../../Domain/Entities/Campaign";
import { useApp } from "../../Hooks/useApp";
import { useAuth } from "../../Hooks/useAuth";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";

type AuthRouteProps = {
    children: JSX.Element;
};

export const AuthRoute = ({ children }: AuthRouteProps): JSX.Element => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [campaignId] = useLocalStorage<number | null>("campaign_id", null);
    const { setAvailableCampaigns, setCampaign } = useApp();
    const [init, setInit] = useState<boolean>(false);

    const seleniaClient = useSeleniaClient();

    useEffect(() => {
        if (user) {
            seleniaClient
                .get<Campaign[]>("campaign")
                .then(({ data }) => {
                    if (campaignId) {
                        const currentCampaign = data.find(
                            (c) => c.id === campaignId
                        );

                        if (currentCampaign !== undefined) {
                            setCampaign(currentCampaign);
                        }
                    }
                    setAvailableCampaigns(data);
                })
                .catch(() => {});
            setInit(true);
        } else {
            navigate("/login");
        }
    }, [
        campaignId,
        navigate,
        seleniaClient,
        setAvailableCampaigns,
        setCampaign,
        user,
    ]);

    return init ? children : <></>;
};
