import { Button, FormGroup, Paper, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";

type CampaignForm = {
    name: string;
    rules: string;
};

type CreateCampaignProps = {
    onClose: () => void;
};

export const CreateCampaign = ({
    onClose,
}: CreateCampaignProps): JSX.Element => {
    const [campaignForm, setCampaignForm] = useState<CampaignForm>({
        name: "",
        rules: "DND",
    });
    const seleniaClient = useSeleniaClient();

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setCampaignForm((cf) => ({
                ...cf,
                [e.target.name]: e.target.value,
            }));
        },
        []
    );

    const createCampaign = useCallback(() => {
        seleniaClient
            .post("campaign", campaignForm)
            .then(() => {
                onClose();
            })
            .catch(() => {});
    }, [campaignForm, onClose, seleniaClient]);

    return (
        <Paper sx={{ padding: "2rem", overflowY: "auto", height: "100%" }}>
            <FormGroup>
                <TextField
                    sx={{ margin: "2rem" }}
                    label="Nom"
                    type="name"
                    name="name"
                    value={campaignForm.name}
                    onChange={handleChange}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") createCampaign();
                    }}
                />
                <Button
                    variant="contained"
                    sx={{ margin: "2rem" }}
                    onClick={createCampaign}
                >
                    Créer la campagne
                </Button>
            </FormGroup>
        </Paper>
    );
};
