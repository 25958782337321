import { Box, Typography } from "@mui/material";

type ModificatorStatProps = {
    name: string;
    value: number;
};

const getModificator = (value: number): string => {
    const result = Math.round((value - 10) / 2);
    return result >= 0 ? "+" + result : `${result}`;
};

export const ModificatorStat = ({ name, value }: ModificatorStatProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "solid",
                borderColor: "white",
                borderWidth: "1px",
                padding: "0.5rem",
            }}
        >
            <Typography>{name}</Typography>
            {value}
            <Typography
                sx={{
                    border: "solid",
                    borderColor: "white",
                    borderWidth: "1px",
                    borderRadius: "100%",
                    padding: "0.3rem",
                    marginTop: "0.5rem",
                }}
            >
                {getModificator(value)}
            </Typography>
        </Box>
    );
};
