import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
    Box,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { LuScrollText } from "react-icons/lu";
import { Fighter } from "../../Domain/Entities/Fighter";

type FighterRowProps = {
    fighterIndex: number;
    fighters: Fighter[];
    onUpdateFighter: (
        fighterIndex: number,
        fieldToUpdate: keyof Fighter | string,
        newValue: any
    ) => void;
    onDeleteFighter: (fighterIndex: number) => void;
    onSelectFighter: (fighter: Fighter) => void;
};

export const FighterRow = ({
    fighters,
    fighterIndex,
    onUpdateFighter,
    onDeleteFighter,
    onSelectFighter,
}: FighterRowProps): JSX.Element => {
    const [removeHealth, setRemoveHealth] = useState<boolean>(false);
    const [healthToRemove, setHealthToRemove] = useState<number>();

    const fighter = useMemo<Fighter>(
        () => fighters[fighterIndex],
        [fighters, fighterIndex]
    );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = isNaN(e.target.value as unknown as number)
                ? e.target.value
                : Number(e.target.value);
            onUpdateFighter(fighterIndex, e.target.name, value);
        },
        [onUpdateFighter, fighterIndex]
    );

    const onHealthFocusOut = useCallback(() => {
        setRemoveHealth(false);
        setHealthToRemove(undefined);
    }, []);

    const onValidateHealthRemove = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") {
                onUpdateFighter(
                    fighterIndex,
                    "health",
                    fighter.entity.health - (healthToRemove ?? 0)
                );
                setRemoveHealth(false);
                setHealthToRemove(undefined);
            }
        },
        [healthToRemove, fighter.entity.health, onUpdateFighter, fighterIndex]
    );

    const resetHealth = useCallback(() => {
        onUpdateFighter(fighterIndex, "health", fighter.character?.healthMax);
    }, [onUpdateFighter, fighter, fighterIndex]);

    return (
        <TableRow>
            <TableCell
                sx={{ color: fighter.playerTeam ? "primary" : undefined }}
            >
                <TextField
                    value={fighter.entity.name}
                    variant="standard"
                    className={fighter.playerTeam ? "FightPlayerName" : ""}
                    name="name"
                    onChange={handleChange}
                />
            </TableCell>
            <TableCell>
                {removeHealth ? (
                    <TextField
                        variant="standard"
                        autoFocus
                        type="number"
                        onBlur={onHealthFocusOut}
                        placeholder={`${fighter.entity.health}`}
                        value={healthToRemove ?? ""}
                        onChange={(e) =>
                            setHealthToRemove(Number(e.target.value))
                        }
                        onKeyDown={onValidateHealthRemove}
                    />
                ) : (
                    <Box sx={{ display: "flex" }}>
                        <TextField
                            variant="standard"
                            value={fighter.entity.health}
                            onClick={() => setRemoveHealth(true)}
                            type="number"
                        />
                        {fighter.playerTeam && (
                            <IconButton
                                sx={{ marginLeft: "0.5rem" }}
                                onClick={() => resetHealth()}
                            >
                                <RefreshIcon />
                            </IconButton>
                        )}
                    </Box>
                )}
            </TableCell>
            <TableCell>
                <TextField
                    value={fighter.entity.armorClass}
                    type="number"
                    variant="standard"
                    name="armorClass"
                    onChange={handleChange}
                />
            </TableCell>
            <TableCell>
                <TextField
                    value={
                        fighter.isCharacter
                            ? fighter.character?.savingThrowSpell
                            : ""
                    }
                    type="number"
                    variant="standard"
                    name="savingThrowSpell"
                    onChange={handleChange}
                />
            </TableCell>
            <TableCell>
                <TextField
                    value={fighter.initiative ?? ""}
                    type="number"
                    variant="standard"
                    name="initiative"
                    onChange={handleChange}
                />
            </TableCell>
            <TableCell>
                {fighter.isCharacter && (
                    <Tooltip title="Data Sheet">
                        <IconButton onClick={() => onSelectFighter(fighter)}>
                            <LuScrollText />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Delete">
                    <IconButton
                        color="error"
                        onClick={() => onDeleteFighter(fighterIndex)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
