import { createBrowserRouter } from "react-router-dom";
import { AuthRoute } from "../../Components/AuthRoute";
import { UnauthRoute } from "../../Components/UnauthRoute";
import { Campaigns } from "../Campaigns";
import { Characters } from "../Characters";
import { Fight } from "../Fight";
import { Login } from "../Login";
import { Wiki } from "../Wiki";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <AuthRoute>
                <Fight />
            </AuthRoute>
        ),
    },
    {
        path: "/characters",
        element: (
            <AuthRoute>
                <Characters />
            </AuthRoute>
        ),
    },
    {
        path: "/wiki",
        element: (
            <AuthRoute>
                <Wiki />
            </AuthRoute>
        ),
    },
    {
        path: "/campaigns",
        element: (
            <AuthRoute>
                <Campaigns />
            </AuthRoute>
        ),
    },
    {
        path: "/login",
        element: (
            <UnauthRoute>
                <Login />
            </UnauthRoute>
        ),
    },
]);
