import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";

type UnauthRouteProps = {
    children: JSX.Element;
};

export const UnauthRoute = ({ children }: UnauthRouteProps): JSX.Element => {
    const { user } = useAuth();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (user !== null && pathname === "/login") {
            navigate("/", { replace: true });
        }
    }, [navigate, pathname, user]);

    return children;
};
