import AddIcon from "@mui/icons-material/Add";
import { Box, Button, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Fighter } from "../../../Domain/Entities/Fighter";
import { Monster } from "../../../Domain/Entities/Monster";

function randomInRange(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
}

type ParamMonstersType = {
    selectedMonster: Monster;
    onSelect: (monsters: Fighter[]) => void;
};

export const ParamMonsters = ({
    selectedMonster,
    onSelect,
}: ParamMonstersType) => {
    const [monsterNumber, setMonsterNumber] = useState<number>(1);
    const [fighters, setFighters] = useState<Fighter[]>([]);

    const tipHealthMax = useMemo(
        () => String(selectedMonster?.health),
        [selectedMonster]
    );

    const changeHealth = useCallback(
        (index: number, health: number) => {
            const tmpFighters = fighters.slice();

            tmpFighters[index].entity.health = health;

            setFighters(tmpFighters);
        },
        [fighters]
    );

    const computeMonster = useCallback(
        (index: number) => {
            const tmpFighters = fighters.slice();

            let monsterHealth = 0;

            try {
                const [dices, bonus] = tipHealthMax
                    .replace(" ", "")
                    .split("(")[1]
                    .split(")")[0]
                    .split("+");

                const [numDices, diceValues] = dices.toLowerCase().split("d");

                monsterHealth = bonus !== undefined ? Number(bonus) : 0;

                for (let i = 0; i < Number(numDices); i++) {
                    monsterHealth += randomInRange(1, Number(diceValues));
                }
            } catch (error) {
                monsterHealth = 0;
            }

            tmpFighters[index].entity.health = monsterHealth;
            tmpFighters[index].initiative = randomInRange(1, 20);

            setFighters(tmpFighters);
        },
        [fighters, tipHealthMax]
    );

    const changeInitiative = useCallback(
        (index: number, initiative: number) => {
            const tmpFighters = fighters.slice();

            tmpFighters[index].initiative = initiative;

            setFighters(tmpFighters);
        },
        [fighters]
    );

    useEffect(() => {
        if (selectedMonster === undefined) {
            return;
        }

        const tmpFighters = new Array<Fighter>();
        for (let i = 0; i < monsterNumber; i++) {
            const monsterCopy = { ...selectedMonster };
            monsterCopy.health = Number(
                String(monsterCopy.health).split(" ")[0]
            );
            monsterCopy.armorClass = String(monsterCopy.armorClass).split(
                " "
            )[0];

            tmpFighters.push({
                id: 0,
                isCharacter: false,
                entity: monsterCopy,
                monster: monsterCopy,
                playerTeam: false,
                turn: 0,
            });
        }

        setFighters(tmpFighters);
    }, [selectedMonster, monsterNumber]);

    return (
        <Box
            sx={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    my: ".5rem",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Button
                    variant="contained"
                    sx={{ marginX: "1rem" }}
                    onClick={() => onSelect(fighters)}
                    startIcon={<AddIcon />}
                >
                    {selectedMonster.name}
                </Button>
            </Box>
            <TextField
                sx={{ my: ".5rem" }}
                label="Nombre de monstres"
                type="number"
                value={monsterNumber}
                variant="standard"
                onChange={(e) => setMonsterNumber(Number(e.target.value))}
            />
            {fighters.map((f, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                        sx={{ marginRight: "1rem" }}
                        onClick={() => computeMonster(index)}
                    >
                        {tipHealthMax}
                    </Button>
                    <TextField
                        sx={{ m: "1rem" }}
                        placeholder="Health"
                        type="number"
                        value={f.entity.health !== 0 ? f.entity.health : ""}
                        variant="standard"
                        onChange={(e) =>
                            changeHealth(index, Number(e.target.value))
                        }
                    />
                    <TextField
                        sx={{ m: "1rem" }}
                        placeholder="Initiative"
                        type="number"
                        value={f.initiative ?? ""}
                        variant="standard"
                        onChange={(e) =>
                            changeInitiative(index, Number(e.target.value))
                        }
                    />
                </Box>
            ))}
        </Box>
    );
};
