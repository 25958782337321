import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Character } from "../../Domain/Entities/Character";

type CharacterPropFieldType = {
    size: number;
    character: Character;
    propField: keyof Character;
    label: string;
    type: "TEXT" | "NUMBER";
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: "TEXT" | "NUMBER"
    ) => void;
};

export const CharacterPropField = ({
    size,
    character,
    propField,
    label,
    onChange,
    type,
}: CharacterPropFieldType) => {
    return (
        <Grid xs={size} sx={{ display: "flex", justifyContent: "center" }}>
            <TextField
                label={label}
                variant="standard"
                value={character[propField] ?? ""}
                name={propField}
                onChange={(e) => onChange(e, type)}
            />
        </Grid>
    );
};
