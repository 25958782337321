import { createContext, useCallback, useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";

type AuthProviderProps = {
    children: JSX.Element;
};

type AuthContextType = {
    user: string | null;
    login: (user: string) => void;
    logout: () => void;
};

const AuthContext = createContext<AuthContextType>({
    user: null,
    login() {},
    logout() {},
});

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [user, setUser] = useLocalStorage<string | null>("user", null);

    const login = useCallback(
        (user: string) => {
            setUser(user);
        },
        [setUser]
    );

    const logout = useCallback(() => {
        setUser(null);
    }, [setUser]);

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    return useContext(AuthContext);
};
