import axios, { AxiosInstance } from "axios";
import { useMemo } from "react";
import { useAuth } from "./useAuth";

export const useSeleniaClient = (): AxiosInstance => {
    const { user, logout } = useAuth();

    return useMemo(() => {
        const SeleniaClient = axios.create({
            baseURL: "https://api.selenia.otchi.ovh",
            headers: { Authorization: `Bearer ${user}` },
        });

        SeleniaClient.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                    logout();
                }
                return Promise.reject(error.message);
            }
        );

        return SeleniaClient;
    }, [logout, user]);
};
