import { createContext, useCallback, useContext, useState } from "react";
import { Campaign } from "../Domain/Entities/Campaign";
import { useLocalStorage } from "./useLocalStorage";

type AuthProviderProps = {
    children: JSX.Element;
};

export type AppContextType = {
    campaign?: Campaign;
    setCampaign: (campaign: Campaign) => void;
    availableCampaigns: Campaign[];
    setAvailableCampaigns: (campaings: Campaign[]) => void;
};

export const AppContext = createContext<AppContextType>({
    setCampaign() {},
    availableCampaigns: [],
    setAvailableCampaigns() {},
});

export const AppProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [campaign, setCampaign] = useState<Campaign>();
    const [availableCampaigns, setAvailableCampaigns] = useState<Campaign[]>(
        () => []
    );
    const [, setCurrentCampaignId] = useLocalStorage<number | null>(
        "campaign_id",
        null
    );

    const setCurrentCampaign = useCallback(
        (campaign: Campaign) => {
            setCampaign(campaign);
            setCurrentCampaignId(campaign.id);
        },
        [setCurrentCampaignId]
    );

    return (
        <AppContext.Provider
            value={{
                campaign,
                setCampaign: setCurrentCampaign,
                availableCampaigns,
                setAvailableCampaigns,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useApp = (): AppContextType => {
    return useContext(AppContext);
};
