import { Box, Button, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useCallback, useEffect, useState } from "react";
import { Character } from "../../Domain/Entities/Character";
import { useApp } from "../../Hooks/useApp";
import { CharacterPropField } from "./CharacterPropField";

type CharacterSheetType = {
    characterToEditId: number;
    onEditSave: (editedCharacter: Character, characterId: number) => void;
};

export const CharacterSheet = ({
    characterToEditId,
    onEditSave,
}: CharacterSheetType) => {
    const { campaign } = useApp();
    const [character, setCharacter] = useState<Character>({
        id: 0,
        armorClass: 0,
        charism: 0,
        classes: "",
        constitution: 0,
        dexterity: 0,
        force: 0,
        health: 0,
        healthMax: 0,
        image: "",
        intelligence: 0,
        name: "",
        player: "",
        savingThrowSpell: 0,
        wisdom: 0,
    });

    useEffect(() => {
        if (campaign) {
            setCharacter(campaign.characters[characterToEditId]);
        }
    }, [campaign, characterToEditId]);

    const handleChange = useCallback(
        (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            type: "TEXT" | "NUMBER"
        ) => {
            setCharacter({
                ...character,
                [e.target.name]:
                    type === "TEXT" ? e.target.value : Number(e.target.value),
            });
        },
        [character]
    );

    const updateCharacter = useCallback(() => {
        onEditSave(character, characterToEditId);
    }, [character, onEditSave, characterToEditId]);

    return (
        <Paper sx={{ padding: "2rem", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5">{character.name}</Typography>
                <Button variant="contained" onClick={updateCharacter}>
                    Valider
                </Button>
            </Box>
            <Grid container spacing={2} rowSpacing={6} sx={{ margin: "1rem" }}>
                <CharacterPropField
                    character={character}
                    label="Force"
                    onChange={handleChange}
                    propField="force"
                    type="NUMBER"
                    size={2}
                />
                <CharacterPropField
                    character={character}
                    type="NUMBER"
                    label="Dextérité"
                    onChange={handleChange}
                    propField="dexterity"
                    size={2}
                />
                <CharacterPropField
                    character={character}
                    label="Constitution"
                    onChange={handleChange}
                    type="NUMBER"
                    propField="constitution"
                    size={2}
                />
                <CharacterPropField
                    character={character}
                    label="Intelligence"
                    onChange={handleChange}
                    type="NUMBER"
                    propField="intelligence"
                    size={2}
                />
                <CharacterPropField
                    character={character}
                    label="Sagesse"
                    onChange={handleChange}
                    type="NUMBER"
                    propField="wisdom"
                    size={2}
                />
                <CharacterPropField
                    character={character}
                    label="Charisme"
                    onChange={handleChange}
                    type="NUMBER"
                    propField="charism"
                    size={2}
                />
                <CharacterPropField
                    character={character}
                    label="Vie"
                    onChange={handleChange}
                    type="NUMBER"
                    propField="healthMax"
                    size={4}
                />
                <CharacterPropField
                    character={character}
                    label="Classe d'armure"
                    onChange={handleChange}
                    type="NUMBER"
                    propField="armorClass"
                    size={4}
                />
                <CharacterPropField
                    character={character}
                    label="JdS Sorts"
                    onChange={handleChange}
                    type="NUMBER"
                    propField="savingThrowSpell"
                    size={4}
                />
            </Grid>
        </Paper>
    );
};
