import axios, { AxiosInstance } from "axios";
import { useMemo } from "react";

export const useMainboardClient = (): AxiosInstance => {
    return useMemo(
        () =>
            axios.create({
                baseURL: "https://api.otchi.ovh",
            }),

        []
    );
};
