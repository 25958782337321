import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import {
    Badge,
    Box,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Campaign } from "../../Domain/Entities/Campaign";
import { useApp } from "../../Hooks/useApp";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";
import { modalStyle } from "../../Styles";
import { CreateCampaign } from "./CreateCampaign";

export const Campaigns = () => {
    const { availableCampaigns, setAvailableCampaigns } = useApp();

    const [createPopup, setCreatePopup] = useState<boolean>(false);

    const seleniaClient = useSeleniaClient();

    const deleteCampaign = useCallback(
        (id: number) => {
            seleniaClient
                .delete("campaign/" + id)
                .then((res) => {
                    let tmpAvailableCampaings = availableCampaigns.slice();

                    tmpAvailableCampaings = tmpAvailableCampaings.filter(
                        (c) => c.id !== id
                    );

                    setAvailableCampaigns(tmpAvailableCampaings);
                })
                .catch(() => {});
        },
        [availableCampaigns, seleniaClient, setAvailableCampaigns]
    );

    const onClosePopup = useCallback(() => {
        setCreatePopup(false);
        seleniaClient
            .get<Campaign[]>("campaign")
            .then(({ data }) => {
                setAvailableCampaigns(data);
            })
            .catch(() => {});
    }, [seleniaClient, setAvailableCampaigns]);

    return (
        <Paper sx={{ padding: "1rem", width: "100%", height: "100%" }}>
            {createPopup && (
                <Modal open={createPopup} onClose={() => setCreatePopup(false)}>
                    <Box sx={modalStyle}>
                        <CreateCampaign onClose={onClosePopup} />
                    </Box>
                </Modal>
            )}
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <IconButton
                    onClick={() => {
                        setCreatePopup(true);
                    }}
                >
                    <AddCircleIcon />
                </IconButton>
            </Box>
            <TableContainer component={Paper} elevation={3}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Règles</TableCell>
                            <TableCell>Joueurs</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {availableCampaigns.map((c) => (
                            <TableRow>
                                <TableCell>{c.name}</TableCell>
                                <TableCell>{c.rules}</TableCell>
                                <TableCell>
                                    <IconButton>
                                        <Badge
                                            badgeContent={c.characters.length}
                                            color="primary"
                                        >
                                            <PersonIcon />
                                        </Badge>
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => deleteCampaign(c.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};
