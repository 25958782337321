import AddCircleIcon from "@mui/icons-material/AddCircle";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
    MRT_ColumnDef,
    MRT_Row,
    MRT_TableInstance,
    MRT_TableOptions,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MagicObject } from "../../Domain/Entities/MagicObject";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";

export const MagicObjects = () => {
    const [, setErrors] = useState<Set<keyof MagicObject>>(() => new Set());
    const [magicobjects, setMagicObjects] = useState<MagicObject[]>([]);

    const seleniaClient = useSeleniaClient();

    useEffect(() => {
        seleniaClient
            .get("magic-object")
            .then((res) => {
                setMagicObjects(res.data);
            })
            .catch((err) => {});
    }, [seleniaClient]);

    const refreshMagicObjects = useCallback(() => {
        seleniaClient
            .patch("magic-object", {})
            .then((res) => {
                setMagicObjects(res.data);
            })
            .catch(() => {});
    }, [seleniaClient]);

    const columns = useMemo<MRT_ColumnDef<MagicObject>[]>(
        () => [
            {
                header: "Nom",
                accessorKey: "name",
                enableEditing: true,
                muiEditTextFieldProps: {
                    type: "text",
                    required: true,
                },
            },
        ],
        []
    );
    const handleCreateMagicObject: MRT_TableOptions<MagicObject>["onCreatingRowSave"] =
        async ({ values, table }) => {
            try {
                const { data } = await seleniaClient.post(
                    "magic-object",
                    values
                );
                const tempMagicObjects = magicobjects.slice();
                tempMagicObjects.unshift(data);
                setMagicObjects(tempMagicObjects);
                table.setCreatingRow(null);
            } catch (error: any) {
                const errors = JSON.parse(error.request.response)
                    .message as string[];
                const keys = new Set<keyof MagicObject>();
                for (const error of errors) {
                    keys.add(error.split(" ")[0] as keyof MagicObject);
                }
                setErrors(keys);
            }
        };

    const handleDeleteMagicObject = async ({
        row,
        table,
    }: {
        row: MRT_Row<MagicObject>;
        table: MRT_TableInstance<MagicObject>;
    }) => {
        try {
            await seleniaClient.delete("magic-object/" + row.id);
            const tempMagicObjects = magicobjects.slice();
            var charIndex = tempMagicObjects.findIndex(
                (char) => char.id === row.id
            );
            tempMagicObjects.splice(charIndex, 1);
            setMagicObjects(tempMagicObjects);
        } catch (error) {
            console.error(error);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: magicobjects,
        initialState: {
            pagination: { pageSize: 8, pageIndex: 0 },
            showColumnFilters: true,
        },
        enableEditing: true,
        layoutMode: "grid",
        createDisplayMode: "row",
        editDisplayMode: "row",
        getRowId: (row) => row.id,
        onCreatingRowCancel: () => {},
        onCreatingRowSave: handleCreateMagicObject,
        enableRowActions: true,
        renderTopToolbarCustomActions: ({ table }) => (
            <div>
                <IconButton
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                >
                    <AddCircleIcon />
                </IconButton>
                <IconButton onClick={refreshMagicObjects}>
                    <CachedIcon />
                </IconButton>
            </div>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        color="error"
                        onClick={() => handleDeleteMagicObject({ row, table })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
    });

    return <MaterialReactTable table={table} />;
};
