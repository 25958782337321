import { useCallback, useState } from "react";

type UseLocalStorageValue<T> = [T, (newValue: T) => void];

export const useLocalStorage = function <T>(
    key: string,
    defaultValue?: T
): UseLocalStorageValue<T> {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const value = window.localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            } else {
                if (defaultValue !== undefined) {
                    window.localStorage.setItem(
                        key,
                        JSON.stringify(defaultValue)
                    );
                }
                return defaultValue;
            }
        } catch (error) {
            return defaultValue;
        }
    });

    const setValue = useCallback(
        (newValue: T) => {
            try {
                window.localStorage.setItem(key, JSON.stringify(newValue));
            } catch (error) {
                console.log(error);
            }
            setStoredValue(newValue);
        },
        [key]
    );

    return [storedValue, setValue];
};
