import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Backgrounds } from "./Backgrounds";
import { Classes } from "./Classes";
import { MagicObjects } from "./MagicObjects";
import { Monsters } from "./Monsters";
import { Poisons } from "./Poisons";
import { Races } from "./Races";
import { Spells } from "./Spells";

export const Wiki = (): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [table, setTable] = useState<JSX.Element>(() => <Races />);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        switch (selectedTab) {
            case 0:
                setTable(<Monsters />);
                break;
            case 1:
                setTable(<Classes />);
                break;
            case 2:
                setTable(<Backgrounds />);
                break;
            case 3:
                setTable(<Poisons />);
                break;
            case 4:
                setTable(<Races />);
                break;
            case 5:
                setTable(<Spells />);
                break;
            case 6:
                setTable(<MagicObjects />);
                break;
            default:
                setTable(<Races />);
                break;
        }
    }, [selectedTab]);

    return (
        <Paper sx={{ height: "100%", width: "100%", padding: "2rem" }}>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    alignItems: "center",
                }}
            >
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab label="Monstres" />
                    <Tab label="Classes" />
                    <Tab label="Historiques" />
                    <Tab label="Poisons" />
                    <Tab label="Races" />
                    <Tab label="Sorts" />
                    <Tab label="Objets Magiques" />
                </Tabs>
            </Box>
            {table}
        </Paper>
    );
};
