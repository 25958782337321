export type Poison = {
    id: string;
    name: string;
    category: PoisonCategory;
    type: PoisonType;
    price: string;
    description: string;
    logo: string;
};

export enum PoisonType {
    BLESSURE = "BLESSURE",
    CONTACT = "CONTACT",
    INGESTION = "INGESTION",
    INHALATION = "INHALATION",
    ERROR = "ERROR",
}

export enum PoisonCategory {
    PLANTE = "PLANTE",
    VENIN = "VENIN",
    TOXINE = "TOXINE",
    MIXTURE = "MIXTURE",
    ERROR = "ERROR",
}
