import AddCircleIcon from "@mui/icons-material/AddCircle";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
    MRT_ColumnDef,
    MRT_Row,
    MRT_TableInstance,
    MRT_TableOptions,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Race } from "../../Domain/Entities/Race";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";

export const Races = () => {
    const [, setErrors] = useState<Set<keyof Race>>(() => new Set());
    const [races, setRaces] = useState<Race[]>([]);

    const seleniaClient = useSeleniaClient();

    useEffect(() => {
        seleniaClient
            .get("race")
            .then((res) => {
                setRaces(res.data);
            })
            .catch((err) => {});
    }, [seleniaClient]);

    const refreshRaces = useCallback(() => {
        seleniaClient
            .patch("race", {})
            .then((res) => {
                setRaces(res.data);
            })
            .catch(() => {});
    }, [seleniaClient]);

    const columns = useMemo<MRT_ColumnDef<Race>[]>(
        () => [
            {
                header: "Nom",
                accessorKey: "name",
                enableEditing: true,
                muiEditTextFieldProps: {
                    type: "text",
                    required: true,
                },
            },
        ],
        []
    );
    const handleCreateRace: MRT_TableOptions<Race>["onCreatingRowSave"] =
        async ({ values, table }) => {
            try {
                const { data } = await seleniaClient.post("race", values);
                const tempRaces = races.slice();
                tempRaces.unshift(data);
                setRaces(tempRaces);
                table.setCreatingRow(null);
            } catch (error: any) {
                const errors = JSON.parse(error.request.response)
                    .message as string[];
                const keys = new Set<keyof Race>();
                for (const error of errors) {
                    keys.add(error.split(" ")[0] as keyof Race);
                }
                setErrors(keys);
            }
        };

    const handleDeleteRace = async ({
        row,
        table,
    }: {
        row: MRT_Row<Race>;
        table: MRT_TableInstance<Race>;
    }) => {
        try {
            await seleniaClient.delete("race/" + row.id);
            const tempRaces = races.slice();
            var charIndex = tempRaces.findIndex((char) => char.id === row.id);
            tempRaces.splice(charIndex, 1);
            setRaces(tempRaces);
        } catch (error) {
            console.error(error);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: races,
        initialState: {
            pagination: { pageSize: 8, pageIndex: 0 },
            showColumnFilters: true,
        },
        enableEditing: true,
        layoutMode: "grid",
        createDisplayMode: "row",
        editDisplayMode: "row",
        getRowId: (row) => row.id,
        onCreatingRowCancel: () => {},
        onCreatingRowSave: handleCreateRace,
        enableRowActions: true,
        renderTopToolbarCustomActions: ({ table }) => (
            <div>
                <IconButton
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                >
                    <AddCircleIcon />
                </IconButton>
                <IconButton onClick={refreshRaces}>
                    <CachedIcon />
                </IconButton>
            </div>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        color="error"
                        onClick={() => handleDeleteRace({ row, table })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
    });

    return <MaterialReactTable table={table} />;
};
