import { Box, Typography } from "@mui/material";
import { Character } from "../../../Domain/Entities/Character";
import { ModificatorStat } from "./ModificatorStat";

type PlayerSheetProps = {
    character: Character;
};

export const PlayerSheet = ({ character }: PlayerSheetProps) => {
    return (
        <Box>
            <Typography variant="overline">{character.player}</Typography>
            <Typography color={"primary.main"} variant="subtitle1">
                {character.classes}
            </Typography>
            <Box sx={{ display: "flex", maxHeight: "50vh" }}>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateRows: "repeat(2,1fr)",
                        gridTemplateColumns: "repeat(3,1fr)",
                        justifyItems: "center",
                        alignItems: "center",
                        width: "50%",
                    }}
                >
                    <ModificatorStat name="FOR" value={character.force} />
                    <ModificatorStat name="DEX" value={character.dexterity} />
                    <ModificatorStat
                        name="CON"
                        value={character.constitution}
                    />
                    <ModificatorStat
                        name="INT"
                        value={character.intelligence}
                    />
                    <ModificatorStat name="CHA" value={character.charism} />
                    <ModificatorStat name="WIS" value={character.wisdom} />
                </Box>
                <Box
                    sx={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img
                        style={{ height: "100%", width: "auto" }}
                        src={character.image}
                        alt=""
                    />
                </Box>
            </Box>
        </Box>
    );
};
