import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { Monster } from "../../../Domain/Entities/Monster";
import { useSeleniaClient } from "../../../Hooks/useSeleniaClient";

type OnSelectMonster = {
    onSelectMonster: (monster: Monster) => void;
};

export const MonsterTable = ({ onSelectMonster }: OnSelectMonster) => {
    const [monsters, setMonsters] = useState<Monster[]>([]);
    const [monsterCount, setMonsterCount] = useState<number>(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        []
    );

    const seleniaClient = useSeleniaClient();

    useEffect(() => {
        const params = {
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
        };

        for (const filter of columnFilters) {
            Object.assign(params, { [filter.id]: filter.value });
        }

        seleniaClient
            .get("monster", {
                params,
            })
            .then((res) => {
                setMonsters(res.data[0]);
                setMonsterCount(res.data[1]);
            })
            .catch((err) => {});
    }, [pagination, columnFilters, seleniaClient]);

    const columns = useMemo<MRT_ColumnDef<Monster>[]>(
        () => [
            {
                header: "Nom",
                accessorKey: "name",
            },
            {
                header: "Puissance",
                accessorKey: "power",
            },
            {
                header: "PV",
                accessorKey: "health",
            },
            {
                header: "XP",
                accessorKey: "experience",
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: monsters,
        initialState: {
            showColumnFilters: true,
        },
        state: {
            pagination: pagination,
        },
        manualPagination: true,
        layoutMode: "grid",
        getRowId: (row, index) => String(index),
        enableRowActions: true,
        rowCount: monsterCount,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        muiPaginationProps: {
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
        },
        renderRowActions: ({ row }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Select">
                    <IconButton onClick={() => onSelectMonster(row.original)}>
                        <AddCircleIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
    });

    return <MaterialReactTable table={table} />;
};
