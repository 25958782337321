import { createTheme } from "@mui/material";

export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "80%",
    height: "80%",
    transform: "translate(-50%, -50%)",
};

export const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#5e35b1",
        },
        secondary: {
            main: "#ede7f6",
        },
    },
});
