// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    height: 100vh;
    display: grid;
    grid-template-rows: 3rem 1fr;
}

.Content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}

.FightPlayerName input {
    color: #5e35b1;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".App {\n    height: 100vh;\n    display: grid;\n    grid-template-rows: 3rem 1fr;\n}\n\n.Content {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 2rem;\n}\n\n.FightPlayerName input {\n    color: #5e35b1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
