import { CssBaseline } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { Navbar } from "../../Components/Navbar";
import { useAuth } from "../../Hooks/useAuth";
import { router } from "./Router";

export const AppContent = (): JSX.Element => {
    const { user } = useAuth();

    return (
        <>
            <CssBaseline />
            <div className="App">
                {user ? <Navbar /> : <div></div>}
                <div className="Content">
                    <RouterProvider router={router} />
                </div>
            </div>
        </>
    );
};
