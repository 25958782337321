import { Paper, Typography } from "@mui/material";
import { useMemo } from "react";
import { Character } from "../../Domain/Entities/Character";
import { Fighter } from "../../Domain/Entities/Fighter";
import { Monster } from "../../Domain/Entities/Monster";
import { MonsterSheet } from "./MonsterSheet/MonsterSheet";
import { PlayerSheet } from "./PlayerSheet/PlayerSheet";

type DataSheetProps = {
    fighter: Fighter;
};

export const DataSheet = ({ fighter }: DataSheetProps) => {
    const playerSheet = useMemo<Character | undefined>(
        () => (fighter.playerTeam ? fighter.character : undefined),
        [fighter]
    );
    const monsterSheet = useMemo<Monster | undefined>(
        () => (!fighter.playerTeam ? fighter.monster : undefined),
        [fighter]
    );

    return (
        <Paper sx={{ padding: "2rem", height: "100%" }}>
            <Typography variant="h5">{fighter.entity.name}</Typography>
            {playerSheet !== undefined && (
                <PlayerSheet character={playerSheet} />
            )}

            {monsterSheet !== undefined && (
                <MonsterSheet monster={monsterSheet} />
            )}
        </Paper>
    );
};
