import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Modal, Paper, Tooltip } from "@mui/material";
import {
    MRT_ColumnDef,
    MRT_Row,
    MRT_TableInstance,
    MRT_TableOptions,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Character } from "../../Domain/Entities/Character";
import { useApp } from "../../Hooks/useApp";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";
import { modalStyle } from "../../Styles";
import { CharacterSheet } from "./CharacterSheet";

export const Characters = (): JSX.Element => {
    const { campaign } = useApp();

    const [characters, setCharacters] = useState<Character[]>([]);
    const [, setErrors] = useState<Set<keyof Character>>(() => new Set());
    const [characterToEditId, setCharacterToEditId] = useState<number>();

    const seleniaClient = useSeleniaClient();

    useEffect(() => {
        if (campaign) {
            seleniaClient
                .get("character", {
                    params: { campaignId: campaign.id },
                })
                .then((res) => {
                    setCharacters(res.data);
                })
                .catch(() => {});
        }
    }, [campaign, seleniaClient]);

    const onEditSave = useCallback(
        (character: Character) => {
            if (campaign) {
                for (const key of Object.keys(character)) {
                    if (character[key as keyof Character] === null) {
                        delete character[key as keyof Character];
                    }
                }

                seleniaClient
                    .patch(`character/${character.id}`, character)
                    .then((res) => {
                        setCharacterToEditId(undefined);
                        const tmpCharacters = characters.slice();
                        const charIndex = tmpCharacters.findIndex(
                            (c) => c.id === character.id
                        );
                        tmpCharacters[charIndex] = character;
                        setCharacters(tmpCharacters);
                    })
                    .catch(() => {});
            }
        },
        [campaign, characters, seleniaClient]
    );

    const columns = useMemo<MRT_ColumnDef<Character>[]>(
        () => [
            {
                header: "Nom",
                accessorKey: "name",
                enableEditing: true,
                muiEditTextFieldProps: {
                    type: "text",
                    required: true,
                },
            },
            {
                header: "Joueur",
                accessorKey: "player",
                enableEditing: true,
                muiEditTextFieldProps: {
                    type: "text",
                    required: true,
                },
            },
            {
                header: "Health Max",
                accessorKey: "healthMax",
                enableEditing: true,
                muiEditTextFieldProps: {
                    type: "number",
                    required: true,
                },
            },
            {
                header: "CA",
                accessorKey: "armorClass",
                enableEditing: true,
                muiEditTextFieldProps: {
                    type: "number",
                    required: true,
                },
            },
            {
                header: "JdS Sorts",
                accessorKey: "savingThrowSpell",
                enableEditing: true,
                muiEditTextFieldProps: {
                    type: "number",
                    required: true,
                },
            },
        ],
        []
    );

    const handleCreateCharacter: MRT_TableOptions<Character>["onCreatingRowSave"] =
        async ({ values, table }) => {
            try {
                if (campaign) {
                    seleniaClient
                        .post("character", {
                            ...values,
                            armorClass: Number(values.armorClass),
                            healthMax: Number(values.healthMax),
                            savingThrowSpell: Number(values.savingThrowSpell),
                            campaignId: campaign.id,
                        })
                        .then((res) => {
                            table.setCreatingRow(null);
                            const tmpCharacters = characters.slice();
                            values.id = res.data.id;
                            tmpCharacters.unshift(values);
                            setCharacters(tmpCharacters);
                        })
                        .catch(() => {});
                }
            } catch (error: any) {
                const errors = JSON.parse(error.request.response)
                    .message as string[];
                const keys = new Set<keyof Character>();
                for (const error of errors) {
                    keys.add(error.split(" ")[0] as keyof Character);
                }
                setErrors(keys);
            }
        };

    const handleDeleteCharacter = async ({
        row,
        table,
    }: {
        row: MRT_Row<Character>;
        table: MRT_TableInstance<Character>;
    }) => {
        try {
            if (campaign) {
                seleniaClient
                    .delete("character/" + row.original.id)
                    .then((res) => {
                        const tmpCharacters = characters.slice();
                        tmpCharacters.splice(row.index, 1);
                        setCharacters(tmpCharacters);
                    })
                    .catch(() => {});
            }
        } catch (error) {
            console.log(error);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: characters,
        enableEditing: true,
        createDisplayMode: "row",
        editDisplayMode: "row",
        getRowId: (row) => `${row.id}`,
        onCreatingRowCancel: () => {},
        onCreatingRowSave: handleCreateCharacter,
        enableRowActions: true,
        rowCount: characters.length,
        renderTopToolbarCustomActions: ({ table }) => (
            <IconButton
                onClick={() => {
                    table.setCreatingRow(true);
                }}
            >
                <AddCircleIcon />
            </IconButton>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => setCharacterToEditId(row.index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        color="error"
                        onClick={() => handleDeleteCharacter({ row, table })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
    });

    return (
        <Paper sx={{ height: "100%", width: "100%", padding: "2rem" }}>
            <Modal
                open={characterToEditId !== undefined}
                onClose={() => setCharacterToEditId(undefined)}
            >
                <Box sx={modalStyle}>
                    {characterToEditId !== undefined && (
                        <CharacterSheet
                            characterToEditId={characterToEditId}
                            onEditSave={onEditSave}
                        />
                    )}
                </Box>
            </Modal>
            <MaterialReactTable table={table} />
        </Paper>
    );
};
