import { Paper, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import { Fighter } from "../../../Domain/Entities/Fighter";
import { Monster } from "../../../Domain/Entities/Monster";
import { MonsterTable } from "./MonsterTable";
import { ParamMonsters } from "./ParamMonsters";

type SelectMonsterProps = {
    onSelect: (monsters: Fighter[]) => void;
};

export const SelectMonster = ({
    onSelect,
}: SelectMonsterProps): JSX.Element => {
    const [selectedMonster, setSelectedMonster] = useState<Monster | undefined>(
        undefined
    );

    return (
        <Paper sx={{ padding: "2rem", overflowY: "auto", height: "100%" }}>
            <Stepper activeStep={selectedMonster === undefined ? 0 : 1}>
                <Step key={"select-monster"}>
                    <StepButton onClick={() => setSelectedMonster(undefined)}>
                        Séléctionner un monstre
                    </StepButton>
                </Step>
                <Step key={"param-monster"}>
                    <StepLabel>Paramétrer le/les monstres</StepLabel>
                </Step>
            </Stepper>
            {selectedMonster === undefined ? (
                <MonsterTable onSelectMonster={setSelectedMonster} />
            ) : (
                <ParamMonsters
                    onSelect={onSelect}
                    selectedMonster={selectedMonster}
                />
            )}
        </Paper>
    );
};
