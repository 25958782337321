import { useMemo } from "react";
import { Monster } from "../../../Domain/Entities/Monster";

type MonsterSheetProps = {
    monster: Monster;
};

export const MonsterSheet = ({ monster }: MonsterSheetProps) => {
    const data = useMemo<string>(() => {
        const datas = monster.originalData.split(
            `<svg><polyline points="0,0 360,2.5 0,5" style="fill:#922610;stroke:#922610;"></polyline>`
        );
        return datas[4];
    }, [monster]);

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: data,
            }}
        />
    );
};
