import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_Row,
    MRT_TableOptions,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Monster } from "../../Domain/Entities/Monster";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";

export const Monsters = () => {
    const [, setErrors] = useState<Set<keyof Monster>>(() => new Set());
    const [monsters, setMonsters] = useState<Monster[]>([]);
    const [monsterCount, setMonsterCount] = useState<number>(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        []
    );

    const seleniaClient = useSeleniaClient();

    useEffect(() => {
        const params = {
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
        };

        for (const filter of columnFilters) {
            Object.assign(params, { [filter.id]: filter.value });
        }

        seleniaClient
            .get("monster", {
                params,
            })
            .then((res) => {
                setMonsters(res.data[0]);
                setMonsterCount(res.data[1]);
            })
            .catch(() => {});
    }, [pagination, columnFilters, seleniaClient]);

    const columns = useMemo<MRT_ColumnDef<Monster>[]>(
        () => [
            {
                header: "Nom",
                accessorKey: "name",
                enableEditing: true,
            },
            {
                header: "Puissance",
                accessorKey: "power",
                enableEditing: true,
            },
            {
                header: "PV",
                accessorKey: "health",
                enableEditing: true,
            },
            {
                header: "CA",
                accessorKey: "armorClass",
                enableEditing: true,
            },
            {
                header: "XP",
                accessorKey: "experience",
                enableEditing: true,
            },
        ],
        []
    );

    const handleCreateMonster: MRT_TableOptions<Monster>["onCreatingRowSave"] =
        async ({ values, table }) => {
            try {
                const { data } = await seleniaClient.post("monster", {
                    power: values.power,
                    name: values.name,
                    health: values.health,
                    experience: Number(values.experience),
                    armorClass: values.armorClass,
                });
                table.setCreatingRow(null);
                const tempMonsters = monsters.slice();
                tempMonsters.push(data);
                setMonsters(tempMonsters);
            } catch (error: any) {
                const errors = JSON.parse(error.request.response)
                    .message as string[];
                const keys = new Set<keyof Monster>();
                for (const error of errors) {
                    keys.add(error.split(" ")[0] as keyof Monster);
                }
                setErrors(keys);
            }
        };

    const handleDeleteMonster = useCallback(
        async ({ row }: { row: MRT_Row<Monster> }) => {
            try {
                await seleniaClient.delete("monster/" + row.original.id);
                const tempMonsters = monsters.slice();
                var charIndex = tempMonsters.findIndex(
                    (char) => char.id === row.id
                );
                tempMonsters.splice(charIndex, 1);
                setMonsters(tempMonsters);
            } catch (error) {
                console.error(error);
            }
        },
        [monsters, seleniaClient]
    );

    const table = useMaterialReactTable({
        columns,
        data: monsters,
        initialState: {
            showColumnFilters: true,
            pagination: { pageSize: 5, pageIndex: 0 },
        },
        state: {
            pagination: pagination,
        },
        enableEditing: true,
        layoutMode: "grid",
        createDisplayMode: "row",
        editDisplayMode: "table",
        rowCount: monsterCount,
        manualPagination: true,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        muiPaginationProps: {
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
        },
        getRowId: (row) => row.id,
        onCreatingRowCancel: () => {},
        onCreatingRowSave: handleCreateMonster,
        enableRowActions: true,
        renderTopToolbarCustomActions: ({ table }) => (
            <div>
                <IconButton
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                >
                    <AddCircleIcon />
                </IconButton>
            </div>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        color="error"
                        onClick={() => handleDeleteMonster({ row })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
    });

    return <MaterialReactTable table={table} />;
};
