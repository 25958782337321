import { Button, FormGroup, Paper, TextField, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { useMainboardClient } from "../../Hooks/useMainboardClient";

type LoginForm = {
    username: string;
    password: string;
};

export const Login = () => {
    const [loginForm, setLoginForm] = useState<LoginForm>({
        username: "",
        password: "",
    });
    const mainboardClient = useMainboardClient();
    const { login: setUser } = useAuth();
    const [error, setError] = useState<boolean>(false);

    const login = useCallback(() => {
        setError(false);
        mainboardClient
            .post("auth/login", loginForm)
            .then(({ data }) => {
                setUser(data.authToken);
            })
            .catch(() => {
                setError(true);
            });
    }, [loginForm, mainboardClient, setUser]);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setLoginForm((lf) => ({ ...lf, [e.target.name]: e.target.value }));
        },
        []
    );

    return (
        <Paper
            sx={{
                height: "60%",
                width: "30%",
                padding: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <FormGroup>
                <TextField
                    sx={{ marginX: "2rem", marginY: "1rem" }}
                    label="Username"
                    type="text"
                    name="username"
                    value={loginForm.username}
                    onChange={handleChange}
                    error={error}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") login();
                    }}
                />
                <TextField
                    sx={{ marginX: "2rem", marginY: "1rem" }}
                    label="Mot de passe"
                    type="password"
                    name="password"
                    value={loginForm.password}
                    onChange={handleChange}
                    error={error}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") login();
                    }}
                />
                {error && (
                    <Typography color={"error"}>
                        Identifiants invalide
                    </Typography>
                )}
                <Button
                    variant="contained"
                    sx={{ margin: "1rem" }}
                    onClick={login}
                >
                    Se connecter
                </Button>
            </FormGroup>
        </Paper>
    );
};
