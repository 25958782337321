import AddCircleIcon from "@mui/icons-material/AddCircle";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
    MRT_ColumnDef,
    MRT_Row,
    MRT_TableInstance,
    MRT_TableOptions,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    Poison,
    PoisonCategory,
    PoisonType,
} from "../../Domain/Entities/Poison";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";

export const Poisons = () => {
    const [, setErrors] = useState<Set<keyof Poison>>(() => new Set());
    const [poisons, setPoisons] = useState<Poison[]>([]);

    const seleniaClient = useSeleniaClient();

    useEffect(() => {
        seleniaClient
            .get("poison")
            .then((res) => {
                setPoisons(res.data);
            })
            .catch((err) => {});
    }, [seleniaClient]);

    const refreshPoisons = useCallback(() => {
        seleniaClient
            .patch("poison", {})
            .then((res) => {
                setPoisons(res.data);
            })
            .catch(() => {});
    }, [seleniaClient]);

    const columns = useMemo<MRT_ColumnDef<Poison>[]>(
        () => [
            {
                header: "Nom",
                accessorKey: "name",
                enableEditing: true,
            },
            {
                header: "Catégorie",
                accessorKey: "category",
                enableEditing: true,
                editVariant: "select",
                editSelectOptions: Object.keys(PoisonCategory).filter(
                    (p) => p !== PoisonCategory.ERROR
                ),
                filterVariant: "select",
                filterSelectOptions: Object.keys(PoisonCategory).filter(
                    (p) => p !== PoisonCategory.ERROR
                ),
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => {
                                let backgroundColor: string;
                                switch (cell.getValue<PoisonCategory>()) {
                                    case PoisonCategory.VENIN:
                                        backgroundColor = "#cad2c5";
                                        break;
                                    case PoisonCategory.MIXTURE:
                                        backgroundColor = "#84a98c";
                                        break;
                                    case PoisonCategory.PLANTE:
                                        backgroundColor = "#52796f";
                                        break;
                                    case PoisonCategory.TOXINE:
                                        backgroundColor = "#354f52";
                                        break;
                                    case PoisonCategory.ERROR:
                                    default:
                                        backgroundColor =
                                            theme.palette.error.main;
                                        break;
                                }
                                return {
                                    backgroundColor,
                                    borderRadius: "0.25rem",
                                    color: "#fff",
                                    maxWidth: "9ch",
                                    p: "0.25rem",
                                };
                            }}
                        >
                            {cell.getValue<string>()}
                        </Box>
                    );
                },
            },
            {
                header: "Type",
                accessorKey: "type",
                enableEditing: true,
                editVariant: "select",
                editSelectOptions: Object.keys(PoisonType).filter(
                    (p) => p !== PoisonType.ERROR
                ),
                filterVariant: "select",
                filterSelectOptions: Object.keys(PoisonType).filter(
                    (p) => p !== PoisonType.ERROR
                ),
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => {
                                let backgroundColor: string;
                                switch (cell.getValue<PoisonType>()) {
                                    case PoisonType.BLESSURE:
                                        backgroundColor = "#76c893";
                                        break;
                                    case PoisonType.CONTACT:
                                        backgroundColor = "#52b69a";
                                        break;
                                    case PoisonType.INGESTION:
                                        backgroundColor = "#34a0a4";
                                        break;
                                    case PoisonType.INHALATION:
                                        backgroundColor = "#168aad";
                                        break;
                                    case PoisonType.ERROR:
                                    default:
                                        backgroundColor =
                                            theme.palette.error.main;
                                        break;
                                }
                                return {
                                    backgroundColor,
                                    borderRadius: "0.25rem",
                                    color: "#fff",
                                    p: "0.25rem",
                                };
                            }}
                        >
                            {cell.getValue<string>()}
                        </Box>
                    );
                },
            },
            {
                header: "Prix",
                accessorKey: "price",
                enableEditing: true,
            },
            {
                header: "Détails",
                accessorKey: "logo",
                enableEditing: true,

                Cell: ({ row }) => {
                    return (
                        <a href={"wiki/poison/" + row.original.id}>
                            <img
                                style={{ height: "30px" }}
                                src={row.getValue<string>("logo")}
                                alt=""
                            ></img>
                        </a>
                    );
                },
            },
        ],
        []
    );
    const handleCreatePoison: MRT_TableOptions<Poison>["onCreatingRowSave"] =
        async ({ values, table }) => {
            try {
                const { data } = await seleniaClient.post("poison", values);
                const tempPoisons = poisons.slice();
                tempPoisons.unshift(data);
                setPoisons(tempPoisons);
                table.setCreatingRow(null);
            } catch (error: any) {
                const errors = JSON.parse(error.request.response)
                    .message as string[];
                const keys = new Set<keyof Poison>();
                for (const error of errors) {
                    keys.add(error.split(" ")[0] as keyof Poison);
                }
                setErrors(keys);
            }
        };

    const handleDeletePoison = async ({
        row,
        table,
    }: {
        row: MRT_Row<Poison>;
        table: MRT_TableInstance<Poison>;
    }) => {
        try {
            await seleniaClient.delete("poison/" + row.id);
            const tempPoisons = poisons.slice();
            var charIndex = tempPoisons.findIndex((char) => char.id === row.id);
            tempPoisons.splice(charIndex, 1);
            setPoisons(tempPoisons);
        } catch (error) {
            console.error(error);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: poisons,
        initialState: {
            pagination: { pageSize: 8, pageIndex: 0 },
            showColumnFilters: true,
        },
        enableEditing: true,
        layoutMode: "grid",
        createDisplayMode: "row",
        editDisplayMode: "cell",
        getRowId: (row) => row.id,
        onCreatingRowCancel: () => {},
        onCreatingRowSave: handleCreatePoison,
        enableRowActions: true,
        renderTopToolbarCustomActions: ({ table }) => (
            <div>
                <IconButton
                    onClick={() => {
                        table.setCreatingRow(true);
                    }}
                >
                    <AddCircleIcon />
                </IconButton>
                <IconButton onClick={refreshPoisons}>
                    <CachedIcon />
                </IconButton>
            </div>
        ),
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        color="error"
                        onClick={() => handleDeletePoison({ row, table })}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
    });

    return <MaterialReactTable table={table} />;
};
