import LogoutIcon from "@mui/icons-material/Logout";
import {
    Button,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { useApp } from "../../Hooks/useApp";
import { useAuth } from "../../Hooks/useAuth";

export const Navbar = (): JSX.Element => {
    const { campaign, availableCampaigns, setCampaign } = useApp();
    const { logout } = useAuth();

    return (
        <Stack
            component={"nav"}
            direction={"row"}
            justifyContent={"space-evenly"}
            paddingX={"2rem"}
            paddingY={"0.5rem"}
            className="Navbar"
            color={"secondary"}
            sx={{ backgroundColor: "primary.main" }}
        >
            {window.location.pathname !== "/campaigns" && (
                <Select
                    label="Campagne"
                    value={campaign ? `${campaign.id}` : ""}
                    variant="standard"
                >
                    {availableCampaigns.map((c) => (
                        <MenuItem
                            key={c.id}
                            value={c.id}
                            onClick={() => setCampaign(c)}
                        >
                            {c.name}
                        </MenuItem>
                    ))}
                    <MenuItem
                        value={""}
                        onClick={() =>
                            (window.location.pathname = "/campaigns")
                        }
                    >
                        <Typography color={"primary"}>
                            Gérer les campagnes...
                        </Typography>
                    </MenuItem>
                </Select>
            )}
            <Button variant="text" color="secondary" href="/">
                Combat
            </Button>
            <Button variant="text" color="secondary" href="/wiki">
                Wiki
            </Button>
            <Button variant="text" color="secondary" href="/characters">
                Personnages
            </Button>
            <IconButton onClick={logout}>
                <LogoutIcon />
            </IconButton>
        </Stack>
    );
};
