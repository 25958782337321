import {
    Box,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Fighter } from "../../Domain/Entities/Fighter";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import { observer } from "mobx-react-lite";
import { Monster } from "../../Domain/Entities/Monster";
import { useApp } from "../../Hooks/useApp";
import { useSeleniaClient } from "../../Hooks/useSeleniaClient";
import { modalStyle } from "../../Styles";
import { DataSheet } from "./DataSheet";
import { FighterRow } from "./FighterRow";
import { SelectMonster } from "./SelectMonster/SelectMonster";

export const Fight = observer((): JSX.Element => {
    const [fighters, setFighters] = useState<Fighter[]>([]);
    const [monsterModal, setMonsterModal] = useState<boolean>(false);
    const [fighterData, setFighterData] = useState<Fighter | undefined>(
        undefined
    );
    const [fightStarted, setFightStarted] = useState<boolean>(false);
    const { campaign } = useApp();
    const seleniaClient = useSeleniaClient();

    const getFightData = useCallback(() => {
        if (campaign === undefined) {
            return;
        }

        seleniaClient
            .get("campaign/fight", {
                params: { campaignId: campaign.id },
            })
            .then(({ data }) => {
                const tmpFighters = new Array<Fighter>();
                for (const fighter of data.fighters) {
                    const tmpFighter: Fighter = { ...fighter };
                    tmpFighter.entity = tmpFighter.character
                        ? tmpFighter.character
                        : (tmpFighter.monster as Monster);
                    tmpFighters.push(tmpFighter);
                }
                setFighters(tmpFighters);
                setFightStarted(data.fightStarted);
            })
            .catch(() => {});
    }, [campaign, seleniaClient]);

    useEffect(() => {
        getFightData();
    }, [getFightData]);

    const updateFighter = useCallback(
        (
            fighterIndex: number,
            fieldToUpdate: keyof Fighter | string,
            newValue: any
        ) => {
            const tmpFighters = fighters.slice();

            if (fighterIndex === -1) {
                return;
            }

            const objectToUpdate =
                fieldToUpdate === "initiative"
                    ? tmpFighters[fighterIndex]
                    : tmpFighters[fighterIndex].entity;
            Object.assign(objectToUpdate, {
                [fieldToUpdate]: newValue,
            });

            setFighters(tmpFighters);
        },
        [fighters, setFighters]
    );

    const startFight = useCallback(() => {
        let tmpFighters = fighters.slice();

        const notReadyFighters = fighters.filter(
            (f) => f.initiative === undefined
        );

        if (notReadyFighters.length > 0) {
            console.log("Manque Init");
            return;
        }

        tmpFighters = tmpFighters.sort((a, b) =>
            (a.initiative ?? 0) > (b.initiative ?? 0) ? -1 : 1
        );

        setFighters(tmpFighters);
        if (!fightStarted) {
            seleniaClient
                .post("campaign/start-fight", {
                    campaignId: campaign?.id,
                    fighters: tmpFighters,
                })
                .then(() => {})
                .catch(() => {});
        } else {
            seleniaClient
                .patch("campaign/" + campaign?.id + "/finish-fight")
                .then(() => {
                    seleniaClient
                        .post("campaign/start-fight", {
                            campaignId: campaign?.id,
                            fighters: tmpFighters,
                        })
                        .then(() => {})
                        .catch(() => {});
                })
                .catch(() => {});
        }
        setFightStarted(true);
    }, [fighters, fightStarted, seleniaClient, campaign?.id]);

    const finishFight = useCallback(() => {
        let tmpFighters = fighters.slice();

        tmpFighters = tmpFighters.filter((f) => f.playerTeam);

        tmpFighters.forEach((f) => (f.initiative = undefined));

        setFighters(tmpFighters);
        setFightStarted(false);
        seleniaClient
            .patch("campaign/" + campaign?.id + "/finish-fight")
            .then(() => {
                getFightData();
            })
            .catch(() => {});
    }, [fighters, seleniaClient, campaign?.id, getFightData]);

    const addMonster = useCallback(
        (monsters: Fighter[]) => {
            const tmpFighters = fighters.slice();

            let isDuringTurn = false;
            let currentTurn = tmpFighters.length > 0 ? tmpFighters[0].turn : 0;
            for (const fighter of tmpFighters) {
                if (fighter.turn !== currentTurn) {
                    isDuringTurn = true;
                }
            }

            for (const monster of monsters) {
                monster.turn = isDuringTurn ? currentTurn + 1 : currentTurn;
                if (fightStarted) {
                    let newMonsterIndex = -1;
                    for (let i = tmpFighters.length - 1; i >= 0; i--) {
                        if (tmpFighters[i].turn < monster.turn) {
                            break;
                        }
                        if (
                            (tmpFighters[i].initiative ?? 0) <=
                            (monster.initiative ?? 0)
                        ) {
                            newMonsterIndex = i;
                        }
                    }
                    newMonsterIndex !== -1
                        ? tmpFighters.splice(newMonsterIndex, 0, monster)
                        : tmpFighters.push(monster);
                } else {
                    tmpFighters.push(monster);
                }
            }
            setFighters(tmpFighters);
            setMonsterModal(false);
        },
        [fighters, setFighters, fightStarted]
    );

    const nextTurn = useCallback(() => {
        let tmpFighters = fighters.slice();

        const fighter = tmpFighters[0];
        fighter.turn++;
        tmpFighters.shift();
        tmpFighters.push(fighter);

        setFighters(tmpFighters);
    }, [fighters]);

    const handleDeleteFighter = useCallback(
        (fighterIndex: number) => {
            const tempFighters = fighters.slice();
            tempFighters.splice(fighterIndex, 1);
            setFighters(tempFighters);
        },
        [fighters]
    );

    return (
        <Paper
            sx={{
                height: "100%",
                width: "100%",
                paddingX: "2rem",
                paddingY: "1rem",
            }}
        >
            <Modal open={monsterModal} onClose={() => setMonsterModal(false)}>
                <Box sx={modalStyle}>
                    <SelectMonster onSelect={addMonster} />
                </Box>
            </Modal>
            <Modal
                open={fighterData !== undefined}
                onClose={() => setFighterData(undefined)}
            >
                <Box sx={modalStyle}>
                    {fighterData && <DataSheet fighter={fighterData} />}
                </Box>
            </Modal>
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <IconButton
                    onClick={() => {
                        setMonsterModal(true);
                    }}
                >
                    <AddCircleIcon />
                </IconButton>
                <IconButton onClick={startFight}>
                    {fightStarted ? <RefreshIcon /> : <PlayCircleOutlineIcon />}
                </IconButton>

                {fightStarted && (
                    <>
                        <IconButton onClick={finishFight}>
                            <CloseIcon />
                        </IconButton>
                        <IconButton onClick={nextTurn}>
                            <SkipNextIcon />
                        </IconButton>
                    </>
                )}
            </Box>

            <TableContainer component={Paper} elevation={3}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Vie</TableCell>
                            <TableCell>CA</TableCell>
                            <TableCell>JdS Sorts</TableCell>
                            <TableCell>Initiative</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fighters.map((f, index) => (
                            <FighterRow
                                key={index}
                                fighters={fighters}
                                fighterIndex={index}
                                onUpdateFighter={updateFighter}
                                onDeleteFighter={handleDeleteFighter}
                                onSelectFighter={() => setFighterData(f)}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
});
