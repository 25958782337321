import { ThemeProvider } from "@emotion/react";
import "./App.css";
import { AppProvider } from "./Hooks/useApp";
import { AuthProvider } from "./Hooks/useAuth";
import { AppContent } from "./Pages/AppContent";
import { theme } from "./Styles";

function App() {
    return (
        <AuthProvider>
            <AppProvider>
                <ThemeProvider theme={theme}>
                    <AppContent />
                </ThemeProvider>
            </AppProvider>
        </AuthProvider>
    );
}

export default App;
